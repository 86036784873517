<template>
  <div>
    <div
      class="card card-custom gutter-b"
      style="height: 90vh; overflow-y: auto"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Average Report by hours of the day:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body" style="display: flex; align-items: center">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
          height="100%"
          width="100%"
          style="height: 100%; width: 100%"
        />
      </div>
    </div>

    <div
      v-if="isShowDetailsVisible"
      id="showDetailsWrapper"
      class="card card-custom gutter-b"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h3>Monday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart
              v-if="isMondaySeriesGenerated"
              :options="options"
              :series="mondaySeries"
            ></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Tuesday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="tuesdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Wednesday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="wednesdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Thursday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="thursdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Friday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="fridaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Saturday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="saturdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Sunday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="sundaySeries"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parse from 'date-fns/parse'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import isMonday from 'date-fns/isMonday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'
import getHours from 'date-fns/getHours'
import VueApexCharts from 'vue-apexcharts'
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'HoursTraffic',
  filters: {
    date: dateFilter,
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      coinTransactions: [],
      smsTransactions: [],
      cardTransactions: [],
      loyaltyTransactions: [],
      hour0: {
        coin: { name: 'hour0', total: 0 },
        sms: { name: 'hour0', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour0', total: 0 },
      },
      hour1: {
        coin: { name: 'hour1', total: 0 },
        sms: { name: 'hour1', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour1', total: 0 },
      },
      hour2: {
        coin: { name: 'hour2', total: 0 },
        sms: { name: 'hour2', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour2', total: 0 },
      },
      hour3: {
        coin: { name: 'hour3', total: 0 },
        sms: { name: 'hour3', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour3', total: 0 },
      },
      hour4: {
        coin: { name: 'hour4', total: 0 },
        sms: { name: 'hour4', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour4', total: 0 },
      },
      hour5: {
        coin: { name: 'hour5', total: 0 },
        sms: { name: 'hour5', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour5', total: 0 },
      },
      hour6: {
        coin: { name: 'hour6', total: 0 },
        sms: { name: 'hour6', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour6', total: 0 },
      },
      hour7: {
        coin: { name: 'hour7', total: 0 },
        sms: { name: 'hour7', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour7', total: 0 },
      },
      hour8: {
        coin: { name: 'hour8', total: 0 },
        sms: { name: 'hour8', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour3', total: 0 },
      },
      hour9: {
        coin: { name: 'hour9', total: 0 },
        sms: { name: 'hour9', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour9', total: 0 },
      },
      hour10: {
        coin: { name: 'hour10', total: 0 },
        sms: { name: 'hour10', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour10', total: 0 },
      },
      hour11: {
        coin: { name: 'hour11', total: 0 },
        sms: { name: 'hour11', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour11', total: 0 },
      },
      hour12: {
        coin: { name: 'hour12', total: 0 },
        sms: { name: 'hour12', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour12', total: 0 },
      },
      hour13: {
        coin: { name: 'hour13', total: 0 },
        sms: { name: 'hour13', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour13', total: 0 },
      },
      hour14: {
        coin: { name: 'hour14', total: 0 },
        sms: { name: 'hour14', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour14', total: 0 },
      },
      hour15: {
        coin: { name: 'hour15', total: 0 },
        sms: { name: 'hour15', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour15', total: 0 },
      },
      hour16: {
        coin: { name: 'hour16', total: 0 },
        sms: { name: 'hour16', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour16', total: 0 },
      },
      hour17: {
        coin: { name: 'hour17', total: 0 },
        sms: { name: 'hour17', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour17', total: 0 },
      },
      hour18: {
        coin: { name: 'hour18', total: 0 },
        sms: { name: 'hour18', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour18', total: 0 },
      },
      hour19: {
        coin: { name: 'hour19', total: 0 },
        sms: { name: 'hour19', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour19', total: 0 },
      },
      hour20: {
        coin: { name: 'hour20', total: 0 },
        sms: { name: 'hour20', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour20', total: 0 },
      },
      hour21: {
        coin: { name: 'hour21', total: 0 },
        sms: { name: 'hour21', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour21', total: 0 },
      },
      hour22: {
        coin: { name: 'hour22', total: 0 },
        sms: { name: 'hour22', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour22', total: 0 },
      },
      hour23: {
        coin: { name: 'hour23', total: 0 },
        sms: { name: 'hour23', total: 0 },
        card: { name: 'hour0', total: 0 },
        loyalty: { name: 'hour23', total: 0 },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: [
            '00h',
            '01h',
            '02h',
            '03h',
            '04h',
            '05h',
            '06h',
            '07h',
            '08h',
            '09h',
            '10h',
            '11h',
            '12h',
            '13h',
            '14h',
            '15h',
            '16h',
            '17h',
            '18h',
            '19h',
            '20h',
            '21h',
            '22h',
            '23h',
          ],
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      monday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      mondaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isMondaySeriesGenerated: false,

      tuesday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      tuesdaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      wednesday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour8', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      wednesdaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      thursday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour8', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      thursdaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      friday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour8', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      fridaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      saturday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour8', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      saturdaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      sunday: {
        hour0: {
          coin: { name: 'hour0', total: 0 },
          sms: { name: 'hour0', total: 0 },
          card: { name: 'hour0', total: 0 },
          loyalty: { name: 'hour0', total: 0 },
        },
        hour1: {
          coin: { name: 'hour1', total: 0 },
          sms: { name: 'hour1', total: 0 },
          card: { name: 'hour1', total: 0 },
          loyalty: { name: 'hour1', total: 0 },
        },
        hour2: {
          coin: { name: 'hour2', total: 0 },
          sms: { name: 'hour2', total: 0 },
          card: { name: 'hour2', total: 0 },
          loyalty: { name: 'hour2', total: 0 },
        },
        hour3: {
          coin: { name: 'hour3', total: 0 },
          sms: { name: 'hour3', total: 0 },
          card: { name: 'hour3', total: 0 },
          loyalty: { name: 'hour3', total: 0 },
        },
        hour4: {
          coin: { name: 'hour4', total: 0 },
          sms: { name: 'hour4', total: 0 },
          card: { name: 'hour4', total: 0 },
          loyalty: { name: 'hour4', total: 0 },
        },
        hour5: {
          coin: { name: 'hour5', total: 0 },
          sms: { name: 'hour5', total: 0 },
          card: { name: 'hour5', total: 0 },
          loyalty: { name: 'hour5', total: 0 },
        },
        hour6: {
          coin: { name: 'hour6', total: 0 },
          sms: { name: 'hour6', total: 0 },
          card: { name: 'hour6', total: 0 },
          loyalty: { name: 'hour6', total: 0 },
        },
        hour7: {
          coin: { name: 'hour7', total: 0 },
          sms: { name: 'hour7', total: 0 },
          card: { name: 'hour7', total: 0 },
          loyalty: { name: 'hour7', total: 0 },
        },
        hour8: {
          coin: { name: 'hour8', total: 0 },
          sms: { name: 'hour8', total: 0 },
          card: { name: 'hour8', total: 0 },
          loyalty: { name: 'hour8', total: 0 },
        },
        hour9: {
          coin: { name: 'hour9', total: 0 },
          sms: { name: 'hour9', total: 0 },
          card: { name: 'hour9', total: 0 },
          loyalty: { name: 'hour9', total: 0 },
        },
        hour10: {
          coin: { name: 'hour10', total: 0 },
          sms: { name: 'hour10', total: 0 },
          card: { name: 'hour10', total: 0 },
          loyalty: { name: 'hour10', total: 0 },
        },
        hour11: {
          coin: { name: 'hour11', total: 0 },
          sms: { name: 'hour11', total: 0 },
          card: { name: 'hour11', total: 0 },
          loyalty: { name: 'hour11', total: 0 },
        },
        hour12: {
          coin: { name: 'hour12', total: 0 },
          sms: { name: 'hour12', total: 0 },
          card: { name: 'hour12', total: 0 },
          loyalty: { name: 'hour12', total: 0 },
        },
        hour13: {
          coin: { name: 'hour13', total: 0 },
          sms: { name: 'hour13', total: 0 },
          card: { name: 'hour13', total: 0 },
          loyalty: { name: 'hour13', total: 0 },
        },
        hour14: {
          coin: { name: 'hour14', total: 0 },
          sms: { name: 'hour14', total: 0 },
          card: { name: 'hour14', total: 0 },
          loyalty: { name: 'hour14', total: 0 },
        },
        hour15: {
          coin: { name: 'hour15', total: 0 },
          sms: { name: 'hour15', total: 0 },
          card: { name: 'hour15', total: 0 },
          loyalty: { name: 'hour15', total: 0 },
        },
        hour16: {
          coin: { name: 'hour16', total: 0 },
          sms: { name: 'hour16', total: 0 },
          card: { name: 'hour16', total: 0 },
          loyalty: { name: 'hour16', total: 0 },
        },
        hour17: {
          coin: { name: 'hour17', total: 0 },
          sms: { name: 'hour17', total: 0 },
          card: { name: 'hour17', total: 0 },
          loyalty: { name: 'hour17', total: 0 },
        },
        hour18: {
          coin: { name: 'hour18', total: 0 },
          sms: { name: 'hour18', total: 0 },
          card: { name: 'hour18', total: 0 },
          loyalty: { name: 'hour18', total: 0 },
        },
        hour19: {
          coin: { name: 'hour19', total: 0 },
          sms: { name: 'hour19', total: 0 },
          card: { name: 'hour19', total: 0 },
          loyalty: { name: 'hour19', total: 0 },
        },
        hour20: {
          coin: { name: 'hour20', total: 0 },
          sms: { name: 'hour20', total: 0 },
          card: { name: 'hour20', total: 0 },
          loyalty: { name: 'hour20', total: 0 },
        },
        hour21: {
          coin: { name: 'hour21', total: 0 },
          sms: { name: 'hour21', total: 0 },
          card: { name: 'hour21', total: 0 },
          loyalty: { name: 'hour21', total: 0 },
        },
        hour22: {
          coin: { name: 'hour22', total: 0 },
          sms: { name: 'hour22', total: 0 },
          card: { name: 'hour22', total: 0 },
          loyalty: { name: 'hour22', total: 0 },
        },
        hour23: {
          coin: { name: 'hour23', total: 0 },
          sms: { name: 'hour23', total: 0 },
          card: { name: 'hour23', total: 0 },
          loyalty: { name: 'hour23', total: 0 },
        },
      },
      sundaySeries: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],

      isReady: false,
      isShowDetailsVisible: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.traffic.forEach((transaction) => {
      this.transactions.push({
        hour: getHours(
          parse(
            transaction.createdAt.replace(' @ ', '-'),
            'dd.MM.yyyy-H:m:s',
            new Date(),
          ),
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
        date: parse(
          transaction.createdAt.replace(' @ ', '-'),
          'dd.MM.yyyy-H:m:s',
          new Date(),
        ),
      })
    })

    this.coinTransactions = this.transactions.filter(
      (transaction) =>
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci',
    )
    this.smsTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_sms',
    )
    this.cardTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_card',
    )
    this.loyaltyTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard',
    )

    this.coinTransactions.forEach((transaction) => {
      if (transaction.hour === 0) {
        this.hour0.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour0.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour0.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour0.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour0.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour0.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour0.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour0.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 1) {
        this.hour1.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour1.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour1.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour1.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour1.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour1.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour1.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour1.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 2) {
        this.hour2.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour2.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour2.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour2.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour2.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour2.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour2.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour2.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 3) {
        this.hour3.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour3.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour3.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour3.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour3.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour3.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour3.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour3.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 4) {
        this.hour4.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour4.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour4.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour4.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour4.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour4.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour4.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour4.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 5) {
        this.hour5.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour5.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour5.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour5.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour5.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour5.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour5.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour5.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 6) {
        this.hour6.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour6.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour6.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour6.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour6.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour6.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour6.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour6.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 7) {
        this.hour7.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour7.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour7.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour7.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour7.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour7.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour7.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour7.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 8) {
        this.hour8.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour8.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour8.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour8.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour8.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour8.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour8.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour8.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 9) {
        this.hour9.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour9.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour9.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour9.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour9.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour9.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour9.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour9.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 10) {
        this.hour10.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour10.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour10.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour10.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour10.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour10.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour10.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour10.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 11) {
        this.hour11.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour11.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour11.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour11.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour11.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour11.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour11.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour11.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 12) {
        this.hour12.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour12.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour12.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour12.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour12.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour12.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour12.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour12.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 13) {
        this.hour13.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour13.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour13.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour13.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour13.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour13.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour13.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour13.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 14) {
        this.hour14.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour14.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour14.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour14.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour14.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour14.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour14.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour14.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 15) {
        this.hour15.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour15.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour15.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour15.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour15.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour15.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour15.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour15.coin.total += parseFloat(transaction.amount)
        }
      }
      if (transaction.hour === 16) {
        this.hour16.coin.total += parseFloat(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour16.coin.total += parseFloat(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour16.coin.total += parseFloat(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour16.coin.total += parseFloat(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour16.coin.total += parseFloat(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour16.coin.total += parseFloat(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour16.coin.total += parseFloat(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour16.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 17) {
        this.hour17.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour17.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour17.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour17.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour17.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour17.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour17.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour17.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 18) {
        this.hour18.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour18.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour18.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour18.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour18.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour18.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour18.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour18.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 19) {
        this.hour19.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour19.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour19.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour19.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour19.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour19.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour19.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour19.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 20) {
        this.hour20.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour20.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour20.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour20.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour20.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour20.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour20.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour20.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 21) {
        this.hour21.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour21.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour21.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour21.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour21.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour21.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour21.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour21.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 22) {
        this.hour22.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour22.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour22.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour22.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour22.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour22.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour22.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour22.coin.total += parseInt(transaction.amount)
        }
      }
      if (transaction.hour === 23) {
        this.hour23.coin.total += parseInt(transaction.amount)
        if (isMonday(transaction.date)) {
          this.monday.hour23.coin.total += parseInt(transaction.amount)
        }
        if (isTuesday(transaction.date)) {
          this.tuesday.hour23.coin.total += parseInt(transaction.amount)
        }
        if (isWednesday(transaction.date)) {
          this.wednesday.hour23.coin.total += parseInt(transaction.amount)
        }
        if (isThursday(transaction.date)) {
          this.thursday.hour23.coin.total += parseInt(transaction.amount)
        }
        if (isFriday(transaction.date)) {
          this.friday.hour23.coin.total += parseInt(transaction.amount)
        }
        if (isSaturday(transaction.date)) {
          this.saturday.hour23.coin.total += parseInt(transaction.amount)
        }
        if (isSunday(transaction.date)) {
          this.sunday.hour23.coin.total += parseInt(transaction.amount)
        }
      }
    })
    this.smsTransactions.forEach((transaction) => {
      if (transaction.hour === 0) {
        this.hour0.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour0.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour0.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour0.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour0.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour0.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour0.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour0.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 1) {
        this.hour1.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour1.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour1.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour1.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour1.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour1.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour1.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour1.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 2) {
        this.hour2.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour2.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour2.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour2.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour2.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour2.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour2.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour2.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 3) {
        this.hour3.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour3.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour3.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour3.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour3.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour3.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour3.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour3.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 4) {
        this.hour4.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour4.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour4.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour4.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour4.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour4.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour4.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour4.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 5) {
        this.hour5.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour5.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour5.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour5.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour5.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour5.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour5.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour5.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 6) {
        this.hour6.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour6.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour6.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour6.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour6.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour6.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour6.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour6.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 7) {
        this.hour7.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour7.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour7.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour7.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour7.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour7.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour7.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour7.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 8) {
        this.hour8.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour8.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour8.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour8.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour8.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour8.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour8.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour8.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 9) {
        this.hour9.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour9.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour9.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour9.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour9.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour9.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour9.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour9.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 10) {
        this.hour10.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour10.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour10.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour10.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour10.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour10.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour10.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour10.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 11) {
        this.hour11.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour11.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour11.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour11.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour11.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour11.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour11.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour11.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 12) {
        this.hour12.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour12.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour12.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour12.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour12.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour12.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour12.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour12.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 13) {
        this.hour13.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour13.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour13.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour13.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour13.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour13.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour13.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour13.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 14) {
        this.hour14.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour14.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour14.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour14.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour14.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour14.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour14.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour14.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 15) {
        this.hour15.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour15.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour15.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour15.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour15.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour15.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour15.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour15.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 16) {
        this.hour16.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour16.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour16.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour16.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour16.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour16.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour16.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour16.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 17) {
        this.hour17.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour17.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour17.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour17.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour17.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour17.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour17.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour17.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 18) {
        this.hour18.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour18.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour18.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour18.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour18.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour18.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour18.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour18.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 19) {
        this.hour19.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour19.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour19.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour19.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour19.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour19.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour19.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour19.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 20) {
        this.hour20.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour20.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour20.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour20.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour20.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour20.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour20.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour20.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 21) {
        this.hour21.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour21.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour21.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour21.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour21.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour21.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour21.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour21.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 22) {
        this.hour22.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour22.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour22.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour22.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour22.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour22.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour22.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour22.sms.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 23) {
        this.hour23.sms.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour23.sms.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour23.sms.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour23.sms.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour23.sms.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour23.sms.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour23.sms.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour23.sms.total += parseFloat(transaction.amount)
      }
    })

    this.cardTransactions.forEach((transaction) => {
      if (transaction.hour === 0) {
        this.hour0.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour0.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour0.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour0.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour0.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour0.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour0.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour0.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 1) {
        this.hour1.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour1.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour1.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour1.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour1.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour1.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour1.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour1.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 2) {
        this.hour2.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour2.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour2.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour2.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour2.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour2.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour2.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour2.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 3) {
        this.hour3.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour3.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour3.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour3.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour3.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour3.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour3.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour3.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 4) {
        this.hour4.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour4.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour4.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour4.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour4.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour4.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour4.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour4.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 5) {
        this.hour5.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour5.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour5.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour5.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour5.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour5.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour5.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour5.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 6) {
        this.hour6.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour6.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour6.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour6.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour6.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour6.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour6.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour6.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 7) {
        this.hour7.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour7.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour7.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour7.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour7.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour7.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour7.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour7.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 8) {
        this.hour8.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour8.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour8.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour8.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour8.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour8.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour8.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour8.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 9) {
        this.hour9.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour9.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour9.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour9.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour9.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour9.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour9.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour9.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 10) {
        this.hour10.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour10.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour10.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour10.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour10.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour10.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour10.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour10.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 11) {
        this.hour11.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour11.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour11.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour11.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour11.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour11.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour11.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour11.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 12) {
        this.hour12.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour12.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour12.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour12.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour12.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour12.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour12.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour12.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 13) {
        this.hour13.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour13.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour13.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour13.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour13.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour13.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour13.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour13.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 14) {
        this.hour14.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour14.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour14.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour14.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour14.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour14.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour14.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour14.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 15) {
        this.hour15.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour15.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour15.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour15.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour15.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour15.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour15.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour15.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 16) {
        this.hour16.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour16.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour16.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour16.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour16.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour16.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour16.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour16.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 17) {
        this.hour17.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour17.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour17.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour17.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour17.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour17.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour17.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour17.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 18) {
        this.hour18.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour18.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour18.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour18.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour18.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour18.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour18.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour18.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 19) {
        this.hour19.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour19.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour19.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour19.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour19.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour19.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour19.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour19.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 20) {
        this.hour20.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour20.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour20.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour20.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour20.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour20.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour20.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour20.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 21) {
        this.hour21.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour21.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour21.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour21.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour21.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour21.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour21.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour21.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 22) {
        this.hour22.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour22.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour22.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour22.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour22.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour22.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour22.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour22.card.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 23) {
        this.hour23.card.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour23.card.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour23.card.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour23.card.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour23.card.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour23.card.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour23.card.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour23.card.total += parseFloat(transaction.amount)
      }
    })

    this.loyaltyTransactions.forEach((transaction) => {
      if (transaction.hour === 0) {
        this.hour0.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour0.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour0.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour0.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour0.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour0.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour0.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour0.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 1) {
        this.hour1.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour1.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour1.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour1.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour1.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour1.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour1.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour1.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 2) {
        this.hour2.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour2.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour2.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour2.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour2.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour2.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour2.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour2.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 3) {
        this.hour3.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour3.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour3.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour3.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour3.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour3.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour3.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour3.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 4) {
        this.hour4.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour4.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour4.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour4.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour4.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour4.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour4.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour4.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 5) {
        this.hour5.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour5.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour5.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour5.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour5.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour5.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour5.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour5.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 6) {
        this.hour6.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour6.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour6.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour6.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour6.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour6.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour6.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour6.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 7) {
        this.hour7.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour7.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour7.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour7.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour7.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour7.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour7.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour7.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 8) {
        this.hour8.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour8.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour8.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour8.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour8.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour8.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour8.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour8.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 9) {
        this.hour9.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour9.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour9.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour9.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour9.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour9.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour9.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour9.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 10) {
        this.hour10.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour10.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour10.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour10.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour10.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour10.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour10.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour10.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 11) {
        this.hour11.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour11.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour11.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour11.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour11.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour11.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour11.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour11.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 12) {
        this.hour12.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour12.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour12.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour12.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour12.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour12.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour12.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour12.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 13) {
        this.hour13.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour13.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour13.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour13.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour13.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour13.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour13.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour13.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 14) {
        this.hour14.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour14.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour14.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour14.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour14.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour14.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour14.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour14.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 15) {
        this.hour15.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour15.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour15.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour15.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour15.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour15.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour15.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour15.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 16) {
        this.hour16.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour16.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour16.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour16.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour16.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour16.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour16.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour16.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 17) {
        this.hour17.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour17.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour17.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour17.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour17.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour17.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour17.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour17.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 18) {
        this.hour18.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour18.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour18.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour18.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour18.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour18.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour18.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour18.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 19) {
        this.hour19.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour19.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour19.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour19.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour19.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour19.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour19.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour19.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 20) {
        this.hour20.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour20.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour20.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour20.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour20.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour20.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour20.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour20.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 21) {
        this.hour21.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour21.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour21.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour21.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour21.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour21.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour21.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour21.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 22) {
        this.hour22.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour22.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour22.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour22.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour22.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour22.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour22.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour22.loyalty.total += parseFloat(transaction.amount)
      }
      if (transaction.hour === 23) {
        this.hour23.loyalty.total += parseInt(transaction.amount)
        if (isMonday(transaction.date))
          this.monday.hour23.loyalty.total += parseFloat(transaction.amount)
        if (isTuesday(transaction.date))
          this.tuesday.hour23.loyalty.total += parseFloat(transaction.amount)
        if (isWednesday(transaction.date))
          this.wednesday.hour23.loyalty.total += parseFloat(transaction.amount)
        if (isThursday(transaction.date))
          this.thursday.hour23.loyalty.total += parseFloat(transaction.amount)
        if (isFriday(transaction.date))
          this.friday.hour23.loyalty.total += parseFloat(transaction.amount)
        if (isSaturday(transaction.date))
          this.saturday.hour23.loyalty.total += parseFloat(transaction.amount)
        if (isSunday(transaction.date))
          this.sunday.hour23.loyalty.total += parseFloat(transaction.amount)
      }
    })

    // coin
    this.series[0].data.push(this.hour0.coin.total)
    this.series[0].data.push(this.hour1.coin.total)
    this.series[0].data.push(this.hour2.coin.total)
    this.series[0].data.push(this.hour3.coin.total)
    this.series[0].data.push(this.hour4.coin.total)
    this.series[0].data.push(this.hour5.coin.total)
    this.series[0].data.push(this.hour6.coin.total)
    this.series[0].data.push(this.hour7.coin.total)
    this.series[0].data.push(this.hour8.coin.total)
    this.series[0].data.push(this.hour10.coin.total)
    this.series[0].data.push(this.hour11.coin.total)
    this.series[0].data.push(this.hour12.coin.total)
    this.series[0].data.push(this.hour13.coin.total)
    this.series[0].data.push(this.hour14.coin.total)
    this.series[0].data.push(this.hour15.coin.total)
    this.series[0].data.push(this.hour16.coin.total)
    this.series[0].data.push(this.hour17.coin.total)
    this.series[0].data.push(this.hour18.coin.total)
    this.series[0].data.push(this.hour19.coin.total)
    this.series[0].data.push(this.hour20.coin.total)
    this.series[0].data.push(this.hour21.coin.total)
    this.series[0].data.push(this.hour22.coin.total)
    this.series[0].data.push(this.hour23.coin.total)

    // sms
    this.series[1].data.push(this.hour0.sms.total)
    this.series[1].data.push(this.hour1.sms.total)
    this.series[1].data.push(this.hour2.sms.total)
    this.series[1].data.push(this.hour3.sms.total)
    this.series[1].data.push(this.hour4.sms.total)
    this.series[1].data.push(this.hour5.sms.total)
    this.series[1].data.push(this.hour6.sms.total)
    this.series[1].data.push(this.hour7.sms.total)
    this.series[1].data.push(this.hour8.sms.total)
    this.series[1].data.push(this.hour10.sms.total)
    this.series[1].data.push(this.hour11.sms.total)
    this.series[1].data.push(this.hour12.sms.total)
    this.series[1].data.push(this.hour13.sms.total)
    this.series[1].data.push(this.hour14.sms.total)
    this.series[1].data.push(this.hour15.sms.total)
    this.series[1].data.push(this.hour16.sms.total)
    this.series[1].data.push(this.hour17.sms.total)
    this.series[1].data.push(this.hour18.sms.total)
    this.series[1].data.push(this.hour19.sms.total)
    this.series[1].data.push(this.hour20.sms.total)
    this.series[1].data.push(this.hour21.sms.total)
    this.series[1].data.push(this.hour22.sms.total)
    this.series[1].data.push(this.hour23.sms.total)

    // sms
    this.series[2].data.push(this.hour0.card.total)
    this.series[2].data.push(this.hour1.card.total)
    this.series[2].data.push(this.hour2.card.total)
    this.series[2].data.push(this.hour3.card.total)
    this.series[2].data.push(this.hour4.card.total)
    this.series[2].data.push(this.hour5.card.total)
    this.series[2].data.push(this.hour6.card.total)
    this.series[2].data.push(this.hour7.card.total)
    this.series[2].data.push(this.hour8.card.total)
    this.series[2].data.push(this.hour10.card.total)
    this.series[2].data.push(this.hour11.card.total)
    this.series[2].data.push(this.hour12.card.total)
    this.series[2].data.push(this.hour13.card.total)
    this.series[2].data.push(this.hour14.card.total)
    this.series[2].data.push(this.hour15.card.total)
    this.series[2].data.push(this.hour16.card.total)
    this.series[2].data.push(this.hour17.card.total)
    this.series[2].data.push(this.hour18.card.total)
    this.series[2].data.push(this.hour19.card.total)
    this.series[2].data.push(this.hour20.card.total)
    this.series[2].data.push(this.hour21.card.total)
    this.series[2].data.push(this.hour22.card.total)
    this.series[2].data.push(this.hour23.card.total)

    // loyalty
    this.series[3].data.push(this.hour0.loyalty.total)
    this.series[3].data.push(this.hour1.loyalty.total)
    this.series[3].data.push(this.hour2.loyalty.total)
    this.series[3].data.push(this.hour3.loyalty.total)
    this.series[3].data.push(this.hour4.loyalty.total)
    this.series[3].data.push(this.hour5.loyalty.total)
    this.series[3].data.push(this.hour6.loyalty.total)
    this.series[3].data.push(this.hour7.loyalty.total)
    this.series[3].data.push(this.hour8.loyalty.total)
    this.series[3].data.push(this.hour10.loyalty.total)
    this.series[3].data.push(this.hour11.loyalty.total)
    this.series[3].data.push(this.hour12.loyalty.total)
    this.series[3].data.push(this.hour13.loyalty.total)
    this.series[3].data.push(this.hour14.loyalty.total)
    this.series[3].data.push(this.hour15.loyalty.total)
    this.series[3].data.push(this.hour16.loyalty.total)
    this.series[3].data.push(this.hour17.loyalty.total)
    this.series[3].data.push(this.hour18.loyalty.total)
    this.series[3].data.push(this.hour19.loyalty.total)
    this.series[3].data.push(this.hour20.loyalty.total)
    this.series[3].data.push(this.hour21.loyalty.total)
    this.series[3].data.push(this.hour22.loyalty.total)
    this.series[3].data.push(this.hour23.loyalty.total)

    let monday = 0
    let tuesday = 0
    let wednesday = 0
    let thursday = 0
    let friday = 0
    let saturday = 0
    let sunday = 0

    eachDayOfInterval(this.range).forEach((day) => {
      if (isMonday(day)) monday++
      if (isTuesday(day)) tuesday++
      if (isWednesday(day)) wednesday++
      if (isThursday(day)) thursday++
      if (isFriday(day)) friday++
      if (isSaturday(day)) saturday++
      if (isSunday(day)) sunday++
    })

    // mondaySeries
    this.mondaySeries[0].data.push(this.monday.hour0.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour1.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour2.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour3.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour4.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour5.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour6.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour7.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour8.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour10.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour11.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour12.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour13.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour14.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour15.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour16.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour17.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour18.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour19.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour20.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour21.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour22.coin.total / monday)
    this.mondaySeries[0].data.push(this.monday.hour23.coin.total / monday)

    this.mondaySeries[1].data.push(this.monday.hour0.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour1.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour2.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour3.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour4.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour5.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour6.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour7.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour8.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour10.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour11.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour12.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour13.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour14.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour15.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour16.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour17.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour18.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour19.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour20.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour21.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour22.sms.total / monday)
    this.mondaySeries[1].data.push(this.monday.hour23.sms.total / monday)

    this.mondaySeries[2].data.push(this.monday.hour0.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour1.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour2.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour3.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour4.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour5.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour6.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour7.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour8.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour10.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour11.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour12.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour13.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour14.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour15.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour16.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour17.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour18.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour19.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour20.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour21.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour22.card.total / monday)
    this.mondaySeries[2].data.push(this.monday.hour23.card.total / monday)

    this.mondaySeries[3].data.push(this.monday.hour0.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour1.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour2.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour3.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour4.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour5.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour6.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour7.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour8.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour10.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour11.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour12.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour13.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour14.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour15.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour16.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour17.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour18.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour19.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour20.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour21.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour22.loyalty.total / monday)
    this.mondaySeries[3].data.push(this.monday.hour23.loyalty.total / monday)

    this.isMondaySeriesGenerated = true

    // tuesdaySeries
    this.tuesdaySeries[0].data.push(this.tuesday.hour0.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour1.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour2.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour3.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour4.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour5.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour6.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour7.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour8.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour10.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour11.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour12.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour13.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour14.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour15.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour16.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour17.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour18.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour19.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour20.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour21.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour22.coin.total / tuesday)
    this.tuesdaySeries[0].data.push(this.tuesday.hour23.coin.total / tuesday)

    this.tuesdaySeries[1].data.push(this.tuesday.hour0.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour1.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour2.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour3.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour4.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour5.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour6.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour7.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour8.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour10.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour11.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour12.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour13.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour14.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour15.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour16.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour17.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour18.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour19.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour20.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour21.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour22.sms.total / tuesday)
    this.tuesdaySeries[1].data.push(this.tuesday.hour23.sms.total / tuesday)

    this.tuesdaySeries[2].data.push(this.tuesday.hour0.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour1.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour2.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour3.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour4.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour5.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour6.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour7.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour8.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour10.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour11.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour12.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour13.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour14.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour15.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour16.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour17.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour18.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour19.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour20.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour21.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour22.card.total / tuesday)
    this.tuesdaySeries[2].data.push(this.tuesday.hour23.card.total / tuesday)

    this.tuesdaySeries[3].data.push(this.tuesday.hour0.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour1.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour2.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour3.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour4.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour5.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour6.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour7.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour8.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour10.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour11.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour12.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour13.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour14.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour15.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour16.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour17.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour18.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour19.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour20.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour21.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour22.loyalty.total / tuesday)
    this.tuesdaySeries[3].data.push(this.tuesday.hour23.loyalty.total / tuesday)

    // wednesdaySeries
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour0.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour1.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour2.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour3.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour4.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour5.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour6.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour7.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour8.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour10.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour11.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour12.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour13.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour14.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour15.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour16.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour17.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour18.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour19.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour20.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour21.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour22.coin.total / wednesday,
    )
    this.wednesdaySeries[0].data.push(
      this.wednesday.hour23.coin.total / wednesday,
    )

    this.wednesdaySeries[1].data.push(
      this.wednesday.hour0.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour1.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour2.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour3.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour4.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour5.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour6.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour7.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour8.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour10.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour11.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour12.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour13.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour14.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour15.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour16.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour17.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour18.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour19.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour20.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour21.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour22.sms.total / wednesday,
    )
    this.wednesdaySeries[1].data.push(
      this.wednesday.hour23.sms.total / wednesday,
    )

    this.wednesdaySeries[2].data.push(
      this.wednesday.hour0.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour1.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour2.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour3.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour4.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour5.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour6.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour7.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour8.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour10.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour11.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour12.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour13.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour14.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour15.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour16.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour17.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour18.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour19.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour20.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour21.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour22.card.total / wednesday,
    )
    this.wednesdaySeries[2].data.push(
      this.wednesday.hour23.card.total / wednesday,
    )

    this.wednesdaySeries[3].data.push(
      this.wednesday.hour0.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour1.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour2.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour3.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour4.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour5.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour6.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour7.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour8.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour10.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour11.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour12.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour13.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour14.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour15.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour16.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour17.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour18.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour19.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour20.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour21.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour22.loyalty.total / tuesday,
    )
    this.wednesdaySeries[3].data.push(
      this.wednesday.hour23.loyalty.total / tuesday,
    )

    // thursdaySeries
    this.thursdaySeries[0].data.push(this.thursday.hour0.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour1.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour2.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour3.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour4.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour5.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour6.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour7.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour8.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour10.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour11.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour12.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour13.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour14.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour15.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour16.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour17.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour18.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour19.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour20.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour21.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour22.coin.total / thursday)
    this.thursdaySeries[0].data.push(this.thursday.hour23.coin.total / thursday)

    this.thursdaySeries[1].data.push(this.thursday.hour0.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour1.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour2.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour3.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour4.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour5.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour6.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour7.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour8.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour10.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour11.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour12.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour13.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour14.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour15.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour16.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour17.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour18.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour19.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour20.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour21.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour22.sms.total / thursday)
    this.thursdaySeries[1].data.push(this.thursday.hour23.sms.total / thursday)

    this.thursdaySeries[2].data.push(this.thursday.hour0.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour1.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour2.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour3.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour4.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour5.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour6.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour7.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour8.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour10.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour11.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour12.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour13.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour14.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour15.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour16.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour17.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour18.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour19.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour20.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour21.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour22.card.total / thursday)
    this.thursdaySeries[2].data.push(this.thursday.hour23.card.total / thursday)

    this.thursdaySeries[3].data.push(
      this.thursday.hour0.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour1.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour2.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour3.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour4.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour5.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour6.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour7.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour8.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour10.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour11.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour12.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour13.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour14.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour15.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour16.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour17.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour18.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour19.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour20.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour21.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour22.loyalty.total / tuesday,
    )
    this.thursdaySeries[3].data.push(
      this.thursday.hour23.loyalty.total / tuesday,
    )

    // fridaySeries
    this.fridaySeries[0].data.push(this.friday.hour0.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour1.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour2.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour3.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour4.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour5.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour6.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour7.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour8.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour10.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour11.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour12.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour13.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour14.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour15.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour16.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour17.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour18.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour19.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour20.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour21.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour22.coin.total / friday)
    this.fridaySeries[0].data.push(this.friday.hour23.coin.total / friday)

    this.fridaySeries[1].data.push(this.friday.hour0.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour1.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour2.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour3.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour4.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour5.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour6.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour7.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour8.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour10.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour11.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour12.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour13.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour14.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour15.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour16.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour17.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour18.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour19.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour20.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour21.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour22.sms.total / friday)
    this.fridaySeries[1].data.push(this.friday.hour23.sms.total / friday)

    this.fridaySeries[2].data.push(this.friday.hour0.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour1.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour2.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour3.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour4.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour5.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour6.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour7.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour8.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour10.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour11.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour12.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour13.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour14.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour15.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour16.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour17.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour18.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour19.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour20.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour21.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour22.card.total / friday)
    this.fridaySeries[2].data.push(this.friday.hour23.card.total / friday)

    this.fridaySeries[3].data.push(this.friday.hour0.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour1.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour2.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour3.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour4.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour5.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour6.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour7.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour8.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour10.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour11.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour12.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour13.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour14.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour15.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour16.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour17.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour18.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour19.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour20.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour21.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour22.loyalty.total / tuesday)
    this.fridaySeries[3].data.push(this.friday.hour23.loyalty.total / tuesday)

    // saturdaySeries
    this.saturdaySeries[0].data.push(this.saturday.hour0.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour1.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour2.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour3.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour4.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour5.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour6.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour7.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour8.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour10.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour11.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour12.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour13.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour14.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour15.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour16.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour17.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour18.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour19.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour20.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour21.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour22.coin.total / saturday)
    this.saturdaySeries[0].data.push(this.saturday.hour23.coin.total / saturday)

    this.saturdaySeries[1].data.push(this.saturday.hour0.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour1.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour2.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour3.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour4.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour5.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour6.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour7.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour8.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour10.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour11.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour12.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour13.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour14.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour15.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour16.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour17.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour18.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour19.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour20.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour21.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour22.sms.total / saturday)
    this.saturdaySeries[1].data.push(this.saturday.hour23.sms.total / saturday)

    this.saturdaySeries[2].data.push(this.saturday.hour0.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour1.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour2.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour3.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour4.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour5.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour6.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour7.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour8.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour10.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour11.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour12.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour13.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour14.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour15.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour16.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour17.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour18.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour19.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour20.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour21.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour22.card.total / saturday)
    this.saturdaySeries[2].data.push(this.saturday.hour23.card.total / saturday)

    this.saturdaySeries[3].data.push(
      this.saturday.hour0.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour1.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour2.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour3.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour4.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour5.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour6.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour7.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour8.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour10.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour11.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour12.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour13.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour14.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour15.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour16.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour17.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour18.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour19.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour20.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour21.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour22.loyalty.total / tuesday,
    )
    this.saturdaySeries[3].data.push(
      this.saturday.hour23.loyalty.total / tuesday,
    )

    // sundaySeries
    this.sundaySeries[0].data.push(this.sunday.hour0.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour1.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour2.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour3.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour4.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour5.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour6.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour7.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour8.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour10.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour11.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour12.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour13.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour14.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour15.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour16.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour17.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour18.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour19.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour20.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour21.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour22.coin.total / sunday)
    this.sundaySeries[0].data.push(this.sunday.hour23.coin.total / sunday)

    this.sundaySeries[1].data.push(this.sunday.hour0.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour1.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour2.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour3.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour4.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour5.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour6.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour7.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour8.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour10.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour11.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour12.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour13.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour14.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour15.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour16.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour17.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour18.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour19.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour20.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour21.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour22.sms.total / sunday)
    this.sundaySeries[1].data.push(this.sunday.hour23.sms.total / sunday)

    this.sundaySeries[2].data.push(this.sunday.hour0.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour1.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour2.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour3.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour4.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour5.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour6.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour7.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour8.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour10.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour11.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour12.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour13.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour14.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour15.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour16.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour17.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour18.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour19.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour20.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour21.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour22.card.total / sunday)
    this.sundaySeries[2].data.push(this.sunday.hour23.card.total / sunday)

    this.sundaySeries[3].data.push(this.sunday.hour0.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour1.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour2.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour3.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour4.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour5.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour6.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour7.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour8.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour10.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour11.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour12.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour13.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour14.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour15.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour16.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour17.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour18.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour19.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour20.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour21.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour22.loyalty.total / tuesday)
    this.sundaySeries[3].data.push(this.sunday.hour23.loyalty.total / tuesday)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    showDetails() {
      this.isShowDetailsVisible = true
      setTimeout(() => {
        document.getElementById('showDetailsWrapper').scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }, 500)
    },
  },
}
</script>
