<template>
  <div class="card card-custom gutter-b" style="height: 60vh; overflow-y: auto">
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-dark">
        Report By Lessors:
        <span class="ml-5"
          ><span
            class="label label-lg label-inline label-light-primary font-weight-bold"
            >{{ range.start | formatDate }}</span
          ></span
        >
        <span
          ><span class="flex-shrink-0 my-2 mx-3"
            ><i class="la la-arrow-right"></i></span
        ></span>
        <span
          ><span
            class="label label-lg label-inline label-light-primary font-weight-bold"
            >{{ range.end | formatDate }}</span
          ></span
        >
      </h3>
    </div>
    <div class="card-body pt-0">
      <LessorReportTable
        v-if="isGenerated"
        :traffic="traffic"
        :selectedLessors="selected"
        :isTokenColumnVisible="true"
      ></LessorReportTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LessorReportTable from '../../../content/components/lessor-report-table/LessorReportTable'

export default {
  name: 'ByLessorTraffic',
  props: {
    traffic: {
      type: Array,
    },
    selected: {
      type: Array,
    },

    range: {
      type: Object,
    },
  },
  components: {
    LessorReportTable,
  },
  data() {
    return {
      isGenerated: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 500)
  },
}
</script>
